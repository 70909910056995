/**
 * Generate permission string
 * @param module
 * @param permission
 * @returns {string}
 */
const prepare = (module, permission) => {
  return `${module}.${permission}`;
};

/**
 * Generate crud permission prop
 * @param module
 * @returns {{create: string, update: string, delete: string, browse: string}}
 */
export const generate = (module) => {
  return {
    browse: prepare(module, 'browse'),
    create: prepare(module, 'create'),
    update: prepare(module, 'update'),
    delete: prepare(module, 'delete')
  }
}

const general = {
  settings_access: 'settings.access',
  settings_update: 'settings.update',
  settings_general_settings_update: 'settings.general_settings_update',
  fake: 'fake',
  table_export: 'table.export',
  table_import: 'table.import',
  can_see_internal_comments: 'comments.can_see_internal_comments',
  can_see_marketing: 'can_see_marketing',
  can_see_support: 'can_see_support',
  can_see_inventory: 'can_see_inventory',
  can_see_history: 'can_see_history',

  can_update_all_comments: 'comments.edit_all_comments',
  can_delete_comments: 'comments.delete_comments',
  can_see_ready_state: 'chs.ready_button',
}

const entity_watchers = {
  browse: 'entity_watchers.browse',
  update: 'entity_watchers.update',

}


const accounts = {
  ...generate('accounts')
}
const buttons = {
  ...generate('buttons')
}
const contacts = {
  ...generate('contacts'),

  can_see_contacts_on_dashboard: 'contacts.can_see_contacts_on_dashboard',
}
const knowledge_bases = {
  ...generate('knowledge_bases')
}
const knowledge_base_categories = {
  ...generate('knowledge_base_categories')
}
const library = {
  ...generate('library')
}

const deals = {
  ...generate('deals'),
  'dashboard': 'deals.dashboard'
}

const age = {
  ...generate('age')
}
const genders = {
  ...generate('genders')
}
const orientation = {
  ...generate('orientations')
}
const ethnicities = {
  ...generate('ethnicities')
}
const languages = {
  ...generate('languages')
}
const pronouns = {
  ...generate('pronouns')
}
const userWorkStatus = {
  ...generate('user_work_status')
}
const userWorkTypes = {
  ...generate('user_work_types')
}
const counties = {
  ...generate('counties')
}
const submissionReports = {
  ...generate('submission_reports'),

  generate_monthly: 'submission_reports.generate_monthly',
  generate_quarterly: 'submission_reports.submission_reports',
}
const generalProducts = {
  ...generate('general_products')
}

const emailCommunication = {
  browse: 'email_communication.browse',
}


const tickets = {
  ...generate('tickets'),
  ebonding: 'tickets.ebonding',
  noc: 'tickets.noc',
  lumen_settings: 'tickets.lumen_setup',
  atos_settings: 'tickets.atos_setup',
  can_see_account_field: 'tickets.can_see_account_field',
  can_see_contact_field: 'tickets.can_see_contact_field',
  can_see_assigned_to_field: 'tickets.can_see_assigned_to_field',
  can_see_assignment_group_field: 'tickets.can_see_assignment_group_field',

  can_see_outage_type_field: 'tickets.can_see_outage_type_field',
  can_see_psap_locations_field: 'tickets.can_see_psap_locations_field',
  can_see_work_notes_field: 'tickets.can_see_work_notes_field',
  can_see_additional_comments_field: 'tickets.can_see_additional_comments_field',
  can_see_severity: 'tickets.can_see_severity',

  can_see_completion_tab: 'tickets.can_see_completion_tab',
  can_see_configuration_tab: 'tickets.can_see_configuration_tab',
  can_see_devices_tab: 'tickets.can_see_devices_tab',
  can_see_timesheet_tab: 'tickets.can_see_timesheet_tab',
  can_see_attachments_tab: 'tickets.can_see_attachments_tab',

  can_see_tickets_on_dashboard: 'tickets.can_see_tickets_on_dashboard',

  setup_notifications: 'tickets.setup_notifications',


  can_edit_outage_type: 'tickets.can_edit_outage_type',
  can_edit_owner: 'tickets.can_edit_owner',
  can_edit_close_code: 'tickets.can_edit_close_code',
  can_edit_resolved_at: 'tickets.can_edit_resolved_at',
  can_edit_root_cause_analysis: 'tickets.can_edit_root_cause_analysis',
  can_edit_close_notes: 'tickets.can_edit_close_notes',
  can_see_internal_tickets: 'tickets.can_see_internal_tickets',
  can_see_kpi: 'tickets.can_see_kpi',

  can_see_origin: 'tickets.can_see_origin',
  can_see_acknowledge_date: 'tickets.can_see_acknowledge_date',
  can_see_on_site_support: 'tickets.can_see_on_site_support',

  archive: 'tickets.archive',

  can_trigger_nexis_message: 'tickets.can_trigger_nexis_message',

  can_see_email_communication: 'email_communication.browse',
  can_see_financial_penalties: 'tickets.can_see_financial_penalties',

  can_connect_with_jira: 'tickets.can_connect_with_jira'
}

const ticket_service_categories = {
  ...generate('ticket_service_categories')
}

const nexisTrigger = {
  ...generate('nexis_trigger'),
}

const groups = {
  ...generate('groups')
}
const users = {
  ...generate('users'),
  login_as_user: 'users.login_as_user',
  is_supervisor: 'users.is_supervisor',
}
const calls = {
  ...generate('calls'),
}

const listViews = {
  ...generate('list-views')
}
const roles = {
  ...generate('roles')
}
const scheduler = {
  scheduler_browse: 'scheduler.browse',
  scheduler_update: 'scheduler.update',
}

const shift = {
  shift_update: 'shift.update',
  shift_create: 'shift.create',
  shift_book_position: 'shift.book_shift_position',
  shift_browse: 'shift.browse',
  shift_automatic_shift_schedule: 'shift.automatic_shift_schedule',
}

const reports = {
  reports_browse: 'reports.browse',
}

const locations = {
  ...generate('locations'),
  can_see_psap_id: 'locations.can_see_psap_id'
}
const locationTags = {
  ...generate('location_tags')
}

const solutions = {
  ...generate('solutions')
}
const psap_profiles = {
  ...generate('psap_profiles')
}
const devices = {
  ...generate('devices')
}
const configuration_items = {
  ...generate('configuration_items')
}
const change_requests = {
  ...generate('change_requests')
}

const events = {
  ...generate('events')
}
const eventType = {
  ...generate('event_type')
}
const eventSource = {
  ...generate('event_source')
}
const eventPurpose = {
  ...generate('event_purpose')
}
const eventTrainingType = {
  ...generate('event_training_type')
}
const noteCategories = {
  ...generate('note_categories')
}
const leaves = {
  ...generate('leaves'),
  can_approve: 'leaves.approve',
}
const callScripts = {
  ...generate('call_scripts')
}
const skills = {
  ...generate('skills'),
}
const mhContacts = {
  ...generate('mh_contacts'),
}
const relationshipTypes = {
  ...generate('relationship_types'),
}
const contactHousehold = {
  ...generate('contact_household'),
}
const contactRelationships = {
  ...generate('contact_relationships'),
}
const callScriptInstances = {
  ...generate('call_script_instances'),
}
const communicationChannelTypes = {
  ...generate('communication_channel_types'),
}
const communicationChannel = {
  ...generate('communication_channels'),
}

const mhCalls = {
  ...generate('mh_calls'),
}

const resources = {
  ...generate('resources'),
}
const resourceTypes = {
  ...generate('resource_types'),
}
const resourceCategories = {
  ...generate('resource_categories'),
}

const resourceReferrals = {
  ...generate('resource_referrals'),
}

const tasks = {
  ...generate('tasks'),
}

const taskTypes = {
  ...generate('task_types'),
}

const issue_management_logs = {
  ...generate('issue_management_logs')
}
const silentMonitorings = {
  ...generate('silent_monitorings')
}
const shiftRoles = {
  ...generate('shift_roles')
}
const folders = {
  ...generate('folders')
}
const notes = {
  ...generate('notes'),
  visibility: 'notes.visibility',
}
const mhCallTypes = {
  ...generate('mh_call_types'),
}

const mhCallCategories = {
  ...generate('mh_call_categories'),
}

const mhCallRisks = {
  ...generate('mh_call_risk_categories'),
}

const mhCallCrisis = {
  ...generate('mh_call_crisis_categories'),
}

const mhCallReferralCategories = {
  ...generate('mh_call_referral_categories'),
}

const forms = {
  ...generate('forms'),
}
const forms_data = {
  ...generate('forms_data'),
}

const realms = {
  ...generate('realms'),
}

const products = {
  ...generate('products'),
}

const ldbs = {
  ...generate('ldbs'),
  settings: 'ldbs.settings'
}

const contracts = {
  ...generate('contracts'),
}

const quotes = {
  ...generate('quotes'),
}

const activities = {
  ...generate('activities'),
}

const maintenance_windows = {
  ...generate('maintenance_windows'),
  send_email: 'maintenance_windows.send_email',
}

const case_trackers = {
  ...generate('case_trackers'),
}

const pre_sale_questionnaires = {
  ...generate('pre_sale_questionnaires'),
}

const handovers = {
  ...generate('handovers'),
}

const field_projects = {
  ...generate('field_projects'),
}

const field_project_changes = {
  ...generate('field_project_changes'),
}

const field_project_tasks = {
  ...generate('field_project_tasks'),
}

const user_positions = {
  ...generate('user_positions'),
}


export default {
  general,
  accounts,
  contacts,
  knowledge_bases,
  knowledge_base_categories,
  tickets,
  ticket_service_categories,
  nexisTrigger,
  groups,
  users,
  roles,
  locations,
  solutions,
  psap_profiles,
  devices,
  configuration_items,
  change_requests,
  scheduler,
  listViews,
  library,
  deals,
  locationTags,
  calls,
  buttons,
  age,
  genders,
  orientation,
  ethnicities,
  languages,
  pronouns,
  userWorkStatus,
  userWorkTypes,
  events,
  eventType,
  eventSource,
  eventPurpose,
  eventTrainingType,
  noteCategories,
  callScripts,
  leaves,
  skills,
  counties,
  mhContacts,
  relationshipTypes,
  contactHousehold,
  contactRelationships,
  callScriptInstances,
  communicationChannelTypes,
  communicationChannel,
  mhCalls,
  resources,
  resourceTypes,
  resourceCategories,
  resourceReferrals,
  tasks,
  taskTypes,
  issue_management_logs,
  silentMonitorings,
  shiftRoles,
  shift,
  folders,
  notes,
  mhCallTypes,
  mhCallRisks,
  mhCallCategories,
  mhCallCrisis,
  mhCallReferralCategories,
  forms,
  forms_data,
  realms,
  submissionReports,
  reports,
  products,
  generalProducts,
  ldbs,
  emailCommunication,
  contracts,
  quotes,
  activities,
  maintenance_windows,
  case_trackers,
  pre_sale_questionnaires,
  handovers,
  field_projects,
  field_project_changes,
  field_project_tasks,
  user_positions,
  entity_watchers
}
