import Permissions from "src/lib/Permissions";

const routes = [
  {
    path: '/',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {meta: {requiresAuth: false}, path: '/login', name: 'login', component: () => import('pages/auth/Login.vue')},
      {meta: {requiresAuth: false}, path: '/sso-authorization', name:'sso-authorization', component: () => import('pages/auth/SsoAuthorization.vue')},
      {meta: {requiresAuth: false}, path: '/forgot-password', name: 'forgot-password', component: () => import('pages/auth/ForgotPassword.vue')},
      {meta: {requiresAuth: false}, path: '/password-reset', name: 'password-reset', component: () => import('pages/auth/PasswordReset.vue')},
      {meta: {requiresAuth: false}, path: '/privacy-policy', name: 'privacy-policy', component: () => import('pages/auth/PrivacyPolicy.vue')},
      {meta: {requiresAuth: false}, path: '/terms-of-use', name: 'terms-of-use', component: () => import('pages/auth/TermsOfUse.vue')},

      {meta: {requiresAuth: false}, path: '/surveys/:token', name: 'surveys', component: () => import('pages/forms_data/forms/PublicSurvey.vue')},
      {meta: {requiresAuth: false}, path: '/ldb-inquiry/create', name: 'create-ldb-inquiry', component: () => import('pages/ldb_inquiry/forms/PublicLdbForm.vue')},

      {meta: {requiresAuth: false}, path: '/maintenance-window-confirm/:token', name: 'maintenance-window-confirm', component: () => import('pages/maintenance_windows/view/MaintenanceWindowConfirmation.vue')},
    ]
  },
  {
    path: '/app',
    component: () => import('layouts/MainLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {meta: {permissions: ''}, name: 'app', path: '', component: () => import('pages/dashboard/Dashboard.vue' )},
      {meta: {permissions: ''}, path: 'search', component: () => import('pages/search/Search.vue')},

      {meta: {permissions: ''}, path: 'articles', component: () => import('pages/knowledge_bases/view/KnowledgeBaseArticles.vue')},
      {meta: {permissions: ''}, path: 'articles/:id', component: () => import('pages/knowledge_bases/view/KnowledgeBaseArticles.vue')},

      {meta: {}, path: 'user-profile/:id', component: () => import('pages/account/UserProfile')},

      {meta: {}, path: 'notifications', component: () => import('pages/notifications/tables/NotificationsTable.vue')},

      // Settings start
      {meta: {permissions: Permissions.users.browse},path: 'settings/users', component: () => import('pages/settings/users/view/UsersList.vue')},
      {meta: {permissions: Permissions.users.browse},path: 'settings/users/:id', component: () => import('pages/settings/users/view/UsersDetail.vue')},

      {meta: {permissions: Permissions.groups.browse},path: 'settings/groups', component: () => import('pages/settings/groups/view/GroupsList.vue')},
      {meta: {permissions: Permissions.groups.browse},path: 'settings/groups/:id', component: () => import('pages/settings/groups/view/GroupsDetail.vue')},

      {meta: {permissions: Permissions.buttons.browse},path: 'settings/buttons', component: () => import('pages/settings/buttons/view/ButtonsList.vue')},
      {meta: {permissions: Permissions.buttons.browse},path: 'settings/buttons/:id', component: () => import('pages/settings/buttons/view/ButtonsDetail.vue')},

      {meta: {permissions: Permissions.roles.browse},path: 'settings/roles', component: () => import('pages/settings/roles/view/RolesList.vue')},
      {meta: {permissions: Permissions.roles.browse},path: 'settings/roles/:id', component: () => import('pages/settings/roles/view/RolesDetail.vue')},

      {meta: {permissions: Permissions.forms_data.browse},path: 'forms/:id', component: () => import('pages/forms_data/view/List.vue')},
      {meta: {permissions: Permissions.forms_data.browse},path: 'forms-data/:id', component: () => import('pages/forms_data/view/Detail.vue')},

      {meta: {permissions: Permissions.age.browse},path: 'settings/age', component: () => import('pages/settings/age/tables/AgeTable.vue')},
      {meta: {permissions: Permissions.genders.browse},path: 'settings/gender', component: () => import('pages/settings/gender/tables/GenderTable.vue')},
      {meta: {permissions: Permissions.orientation.browse},path: 'settings/orientation', component: () => import('pages/settings/orientation/tables/Table')},
      {meta: {permissions: Permissions.ethnicities.browse},path: 'settings/ethnicity', component: () => import('pages/settings/ethnicity/tables/Table')},
      {meta: {permissions: Permissions.languages.browse},path: 'settings/language', component: () => import('pages/settings/language/tables/Table')},
      {meta: {permissions: Permissions.pronouns.browse},path: 'settings/pronouns', component: () => import('pages/settings/pronouns/tables/Table')},
      {meta: {permissions: Permissions.userWorkStatus.browse},path: 'settings/user_work_status', component: () => import('pages/settings/userWorkStatus/tables/Table')},
      {meta: {permissions: Permissions.userWorkTypes.browse},path: 'settings/user_work_type', component: () => import('pages/settings/userWorkType/tables/Table')},
      {meta: {permissions: Permissions.eventType.browse},path: 'settings/event_type', component: () => import('pages/settings/eventType/tables/Table')},
      {meta: {permissions: Permissions.eventSource.browse},path: 'settings/event_source', component: () => import('pages/settings/eventSource/tables/Table')},
      {meta: {permissions: Permissions.eventPurpose.browse},path: 'settings/event_purpose', component: () => import('pages/settings/eventPurpose/tables/Table')},
      {meta: {permissions: Permissions.eventTrainingType.browse},path: 'settings/event_training_type', component: () => import('pages/settings/eventTrainingType/tables/Table')},
      {meta: {permissions: Permissions.noteCategories.browse},path: 'settings/note_category', component: () => import('pages/settings/noteCategories/tables/Table')},
      {meta: {permissions: Permissions.notes.browse},path: 'settings/notes', component: () => import('pages/notes/tables/NotesTableSettings')},
      {meta: {permissions: Permissions.knowledge_bases.browse},path: 'settings/knowledge_bases', component: () => import('pages/settings/knowledge_bases/tables/KnowledgeBaseTable.vue')},
      {meta: {permissions: Permissions.knowledge_bases.browse},path: 'settings/knowledge_bases/:id', component: () => import('pages/settings/knowledge_bases/view/Detail.vue')},

      {meta: {permissions: Permissions.knowledge_base_categories.browse},path: 'settings/knowledge_base_categories', component: () => import('pages/settings/knowledge_base_categories/tables/KnowledgeBaseCategoryTable.vue')},
      {meta: {permissions: Permissions.callScripts.browse},path: 'settings/call_scripts', component: () => import('pages/settings/call_scripts/tables/Table')},
      {meta: {permissions: Permissions.skills.browse},path: 'settings/skills', component: () => import('pages/settings/skills/tables/Table')},
      {meta: {permissions: Permissions.counties.browse},path: 'settings/counties', component: () => import('pages/settings/counties/tables/Table')},
      {meta: {permissions: Permissions.relationshipTypes.browse},path: 'settings/relationship_types', component: () => import('pages/settings/relationshipTypes/tables/Table')},
      {meta: {permissions: Permissions.communicationChannelTypes.browse},path: 'settings/communication_channel_types', component: () => import('pages/settings/communicationChannelTypes/tables/Table')},
      {meta: {permissions: Permissions.resourceCategories.browse},path: 'settings/resource_categories', component: () => import('pages/settings/resourceCategories/tables/Table')},
      {meta: {permissions: Permissions.resourceTypes.browse},path: 'settings/resource_types', component: () => import('pages/settings/resourceTypes/tables/Table')},
      {meta: {permissions: Permissions.taskTypes.browse},path: 'settings/task_types', component: () => import('pages/settings/taskTypes/tables/Table')},
      {meta: {permissions: Permissions.mhCallTypes.browse},path: 'settings/mh_call_types', component: () => import('pages/settings/mhCallTypes/tables/Table')},
      {meta: {permissions: Permissions.ticket_service_categories.browse},path: 'settings/ticket_service_categories', component: () => import('pages/settings/ticketServiceCategories/tables/Table')},


      {meta: {permissions: Permissions.mhCallCrisis.browse},path: 'settings/mh_call_crisis_categories', component: () => import('pages/settings/mhCallCrisis/tables/Table')},
      {meta: {permissions: Permissions.mhCallReferralCategories.browse},path: 'settings/mh_call_referral_categories', component: () => import('pages/settings/mhCallReferralCategories/tables/Table')},
      {meta: {permissions: Permissions.products.browse},path: 'settings/products', component: () => import('pages/settings/products/tables/Table')},

      {meta: {permissions: Permissions.mhCallRisks.browse},path: 'settings/mh_call_risks', component: () => import('pages/settings/mhCallRisks/tables/Table')},
      {meta: {permissions: Permissions.mhCallCategories.browse},path: 'settings/mh_call_categories', component: () => import('pages/settings/mhCallCategories/tables/Table')},

      {meta: {permissions: Permissions.general.settings_access},path: 'settings/settings', component: () => import('pages/settings/settings/ApplicationSettings')},
      {meta: {permissions: Permissions.ldbs.settings},path: 'settings/ldb-settings', component: () => import('pages/settings/settings/LdbSettings')},

      {meta: {permissions: Permissions.shiftRoles.browse},path: 'settings/shift_roles', component: () => import('pages/settings/shiftRoles/tables/Table')},

      {meta: {permissions: Permissions.forms.browse},path: 'settings/forms', component: () => import('pages/settings/forms/view/FormList.vue')},

      {meta: {permissions: Permissions.realms.browse},path: 'settings/realms', component: () => import('pages/settings/realms/tables/RealmsTable')},

      {meta: {permissions: Permissions.tickets.setup_notifications},path: 'settings/ticket-notifications', component: () => import('pages/settings/settings/TicketNotificationSettings')},

      {meta: {permissions: Permissions.generalProducts.browse},path: 'settings/general_products', component: () => import('pages/settings/generalProducts/tables/GeneralProductTable.vue')},


      // Settings end

      // HMCC start
      {meta: {permissions: Permissions.events.browse},path: 'events', component: () => import('pages/events/tables/Table')},
      {meta: {permissions: Permissions.events.browse},path: 'events/:id', component: () => import('pages/events/view/Detail')},

      {meta: {permissions: Permissions.leaves.browse},path: 'leaves', component: () => import('pages/leaves/view/LeaveList.vue')},
      {meta: {permissions: Permissions.leaves.browse},path: 'leaves/:id', component: () => import('pages/leaves/view/LeaveDetail.vue')},

      {meta: {permissions: Permissions.mhContacts.browse},path: 'mh-contacts', component: () => import('pages/mh_contacts/view/MhContactList.vue')},
      {meta: {permissions: Permissions.mhContacts.browse},path: 'mh-contacts/:id', component: () => import('pages/mh_contacts/view/MhContactDetail.vue')},

      {meta: {permissions: Permissions.resources.browse},path: 'resources', component: () => import('pages/resources/view/ResourceList.vue')},
      {meta: {permissions: Permissions.resources.browse},path: 'resources/:id', component: () => import('pages/resources/view/ResourceDetail.vue')},

      {meta: {permissions: Permissions.silentMonitorings.browse},path: 'silent-monitoring/:id', component: () => import('pages/silent_monitorings/views/SilentMonitoringDetail')},

      {meta: {permissions: Permissions.folders.browse},path: 'folders', component: () => import('pages/folders/view/FolderList.vue')},

      {meta: {permissions: Permissions.tickets.lumen_settings},path: 'settings/lumen-settings', component: () => import('pages/settings/settings/LumenSettings.vue')},
      {meta: {permissions: Permissions.tickets.atos_settings},path: 'settings/atos-settings', component: () => import('pages/settings/settings/AtosSettings.vue')},

      {meta: {permissions: Permissions.tickets.can_connect_with_jira},path: 'settings/jira-settings', component: () => import('pages/settings/settings/JiraSettings.vue')},
      {meta: {permissions: Permissions.nexisTrigger.browse},path: 'settings/nexis_trigger', component: () => import('pages/settings/nexisTriggers/tables/Table')},

      {meta: {permissions: Permissions.maintenance_windows.browse},path: 'maintenance-windows', component: () => import('pages/maintenance_windows/view/MaintenanceWindowList.vue')},
      {meta: {permissions: Permissions.maintenance_windows.browse},path: 'maintenance-windows/:id', component: () => import('pages/maintenance_windows/view/MaintenanceWindowDetail.vue')},

      {meta: {permissions: Permissions.pre_sale_questionnaires.browse},path: 'pre-sale-questionnaires', component: () => import('pages/pre_sale_questionnaires/view/PreSaleQuestionnairesList.vue')},
      {meta: {permissions: Permissions.pre_sale_questionnaires.browse},path: 'pre-sale-questionnaires/:id', component: () => import('pages/pre_sale_questionnaires/view/PreSaleQuestionnairesDetail.vue')},

      // HMCC end

      {meta: {permissions: Permissions.accounts.browse},path: 'accounts', component: () => import('pages/accounts/view/AccountsList.vue')},
      {meta: {permissions: Permissions.accounts.browse},path: 'accounts/:id', component: () => import('pages/accounts/view/AccoutsDetail.vue')},

      {meta: {permissions: Permissions.case_trackers.browse},path: 'case-trackers', component: () => import('pages/case_trackers/view/CaseTrackerList.vue')},
      {meta: {permissions: Permissions.case_trackers.browse},path: 'case-trackers/:id', component: () => import('pages/case_trackers/view/CaseTrackerDetail.vue')},


      {meta: {permissions: Permissions.contacts.browse},path: 'contacts', component: () => import('pages/contacts/view/ContactsList.vue')},
      {meta: {permissions: Permissions.contacts.browse},path: 'contacts/:id', component: () => import('pages/contacts/view/ContactDetail.vue')},

      {meta: {permissions: Permissions.deals.browse},name: 'deals', path: 'deals', component: () => import('pages/deals/view/DealsList.vue')},
      {meta: {permissions: Permissions.deals.browse},path: 'deals/:id', component: () => import('pages/deals/view/DealDetail.vue')},

      {meta: {permissions: Permissions.contracts.browse},name: 'contracts', path: 'contracts', component: () => import('pages/contracts/view/ContractsList.vue')},
      {meta: {permissions: Permissions.contracts.browse},path: 'contracts/:id', component: () => import('pages/contracts/view/ContractDetail.vue')},

      {meta: {permissions: Permissions.quotes.browse},name: 'quotes', path: 'quotes', component: () => import('pages/quotes/view/QuotesList.vue')},
      {meta: {permissions: Permissions.quotes.browse},path: 'quotes/:id', component: () => import('pages/quotes/view/QuoteDetail.vue')},

      {meta: {permissions: Permissions.activities.browse},name: 'activities', path: 'activities', component: () => import('pages/activities/view/ActivitiesList.vue')},
      {meta: {permissions: Permissions.activities.browse},path: 'activities/:id', component: () => import('pages/activities/view/ActivityDetail.vue')},

      {meta: {permissions: Permissions.calls.browse},path: 'calls', component: () => import('pages/calls/view/CallList')},
      {meta: {permissions: Permissions.calls.browse},path: 'calls/:id', component: () => import('pages/calls/view/CallDetail.vue')},

      {meta: {permissions: Permissions.issue_management_logs.browse},path: 'issue_management_logs', component: () => import('pages/issue_management_logs/view/IssueManagementLogList')},
      {meta: {permissions: Permissions.issue_management_logs.browse},path: 'issue_management_logs/:id', component: () => import('pages/issue_management_logs/view/IssueManagementLogDetail.vue')},

      {meta: {permissions: Permissions.locations.browse},path: 'locations', component: () => import('pages/locations/view/LocationsList.vue')},
      {meta: {permissions: Permissions.locations.browse},path: 'locations/:id', component: () => import('pages/locations/view/LocationDetail.vue')},

      {meta: {permissions: Permissions.locations.browse},path: 'location-tags', component: () => import('pages/locations_tags/view/LocationTagList.vue')},
      {meta: {permissions: Permissions.locations.browse},path: 'location-tags/:id', component: () => import('pages/locations_tags/view/LocationTagDetail.vue')},


      {meta: {permissions: Permissions.solutions.browse},path: 'solutions', component: () => import('pages/solutions/view/SolutionsList')},
      {meta: {permissions: Permissions.solutions.browse},path: 'solutions/:id', component: () => import('pages/solutions/view/SolutionDetail.vue')},

      {meta: {permissions: Permissions.library.browse},path: 'library', component: () => import('pages/library/view/LibraryList.vue')},
      {meta: {permissions: Permissions.library.browse},path: 'library/:id', component: () => import('pages/library/view/LibraryDetail.vue')},


      {meta: {permissions: Permissions.tickets.browse}, name: 'tickets',path: 'tickets', component: () => import('pages/tickets/view/TicketsList.vue')},
      {meta: {permissions: Permissions.tickets.browse}, name: 'ticketDetails',path: 'tickets/:id', component: () => import('pages/tickets/view/TicketDetail.vue')},

      {meta: {permissions: Permissions.psap_profiles.browse},path: 'psap-profiles', component: () => import('pages/psap_profiles/view/PsapProfileList.vue')},
      {meta: {permissions: Permissions.psap_profiles.browse},path: 'psap-profiles/:id', component: () => import('pages/psap_profiles/view/PsapProfileDetail.vue')},

      {meta: {permissions: Permissions.devices.browse},path: 'devices', component: () => import('pages/devices/view/DeviceList.vue')},
      {meta: {permissions: Permissions.devices.browse},path: 'devices/:id', component: () => import('pages/devices/view/DeviceDetail.vue')},

      {meta: {permissions: Permissions.devices.browse},path: 'configuration_items', component: () => import('pages/configuration_items/view/ConfigurationItemList.vue')},
      {meta: {permissions: Permissions.devices.browse},path: 'configuration_items/:id', component: () => import('pages/configuration_items/view/ConfigurationItemDetail.vue')},

      {meta: {permissions: Permissions.change_requests.browse},path: 'change_requests', component: () => import('pages/change_requests/view/ChangeRequestList.vue')},
      {meta: {permissions: Permissions.change_requests.browse},path: 'change_requests/:id', component: () => import('pages/change_requests/view/ChangeRequestDetail.vue')},

      {meta: {permissions: Permissions.mhCalls.browse},path: 'mh-calls', component: () => import('pages/mh_calls/view/MhCallList.vue')},
      {meta: {permissions: Permissions.mhCalls.browse}, name: 'mh_calls.details', path: 'mh-calls/:id', component: () => import('pages/mh_calls/view/MhCallDetail.vue')},
      {meta: {permissions: Permissions.mhCalls.browse}, name: 'mh_calls.details_by_channel', path: 'mh-calls-by-channel/:channelId', component: () => import('pages/mh_calls/view/MhCallDetail.vue')},

      {meta: {permissions: Permissions.scheduler.scheduler_browse},path: 'scheduler', component: () => import('pages/scheduler/view/SchedulerView.vue')},

      {meta: {permissions: Permissions.general.settings_access},path: 'settings', component: () => import('pages/settings/Settings.vue')},

      {meta: {permissions: Permissions.tasks.browse},path: 'tasks', component: () => import('pages/tasks/view/TaskList.vue')},
      {meta: {permissions: Permissions.tasks.browse}, name: 'tasks.details', path: 'tasks/:id', component: () => import('pages/tasks/view/TaskDetail.vue')},

      {meta: {permissions: Permissions.submissionReports.browse},path: 'reports/monthly-submission-reports-v1', component: () => import('pages/submission_reports/view/monthly-v1/MonthlySubmissionReportV1List.vue')},
      {meta: {permissions: Permissions.submissionReports.browse},path: 'reports/monthly-submission-reports-v1/:id', component: () => import('pages/submission_reports/view/monthly-v1/MonthlySubmissionReportV1Detail.vue')},

      {meta: {permissions: Permissions.submissionReports.browse},path: 'reports/monthly-submission-reports-v2', component: () => import('pages/submission_reports/view/monthly-v2/MonthlySubmissionReportV2List.vue')},
      {meta: {permissions: Permissions.submissionReports.browse},path: 'reports/monthly-submission-reports-v2/:id', component: () => import('pages/submission_reports/view/monthly-v2/MonthlySubmissionReportV2Detail.vue')},

      {meta: {permissions: Permissions.submissionReports.browse},path: 'reports/quarterly-submission-reports', component: () => import('pages/submission_reports/view/quarterly/QuarterlySubmissionReportList.vue')},
      {meta: {permissions: Permissions.submissionReports.browse},path: 'reports/quarterly-submission-reports/:id', component: () => import('pages/submission_reports/view/quarterly/QuarterlySubmissionReportDetail.vue')},

      {meta: {permissions: Permissions.ldbs.browse},path: 'ldb_inquiry', component: () => import('pages/ldb_inquiry/view/LdbList.vue')},
      {meta: {permissions: Permissions.ldbs.browse},path: 'ldb_inquiry/:id', component: () => import('pages/ldb_inquiry/view/LdbDetail.vue')},

      {meta: {permissions: Permissions.handovers.browse},path: 'handovers', component: () => import('pages/handover/view/HandoverList.vue')},
      {meta: {permissions: Permissions.handovers.browse},path: 'handovers/:id', component: () => import('pages/handover/view/HandoverDetail.vue')},

      {meta: {permissions: Permissions.field_projects.browse},path: 'field_projects', component: () => import('pages/field_projects/view/FieldProjectList.vue')},
      {meta: {permissions: Permissions.field_projects.browse},path: 'field_projects/:id', component: () => import('pages/field_projects/view/FieldProjectDetail.vue')},

      {meta: {permissions: Permissions.field_project_changes.browse},path: 'field_project_changes', component: () => import('pages/field_projects/view/FieldProjectChangeList.vue')},
      {meta: {permissions: Permissions.field_project_changes.browse},path: 'field_project_changes/:id', component: () => import('pages/field_projects/view/FieldProjectChangeDetail.vue')},

      {meta: {permissions: Permissions.field_project_tasks.browse},path: 'field_project_tasks', component: () => import('pages/field_projects/view/FieldProjectTaskList.vue')},
      {meta: {permissions: Permissions.field_project_tasks.browse},path: 'field_project_tasks/:id', component: () => import('pages/field_projects/view/FieldProjectTaskDetail.vue')},

      {meta: {permissions: Permissions.user_positions.browse},path: 'settings/user_positions', component: () => import('pages/settings/user_positions/view/UserPositionsList.vue')},
      {meta: {permissions: Permissions.user_positions.browse},path: 'settings/user_positions/:id', component: () => import('pages/settings/user_positions/view/UserPositionsDetail.vue')},

    ]
  },
  {
    path: '/app/account',
    component: () => import('layouts/MainLayout.vue'),
    meta: {requiresAuth: true},
    children: [
      {path: '', component: () => import('pages/account/AccountPage.vue')},
    ]
  }
]


// Always leave this as last one
if (process.env.MODE !== 'ssr') {
  routes.push({
    path: '*',
    component: () => import('pages/Error404.vue')
  })
}


export default routes
